
import { Injectable } from '@angular/core';



// @Injectable()
@Injectable({
  providedIn: 'root'
})
export class RequestModelService {

  constructor() {
  }

  getStaticRequestDataTemp() {
    return {
      'PWSESSIONRS': {
        'PWPROCESSRS': {
          'PWHEADER': {
            'IN_PROCESS_ID': '16',
            'APP_ID': "",//"HTL",
            'ORG_ID': "",//"HTL",
            'OS_VERSION': '9',
            'OUT_PROCESS_ID': 'get_channel_content',
            'LOGIN_ID': 'KPOINT',
            'PASSWORD': ''
          },
          'PWDATA': {

          },
          'PWERROR': ''
        }
      }
    }
  }

  getStaticRequestData() {
    return {
      'PWSESSIONRS': {
        'PWPROCESSRS': {
          'PWHEADER': {
            'IN_PROCESS_ID': '',
            'APP_ID': "HRM",
            'ORG_ID': "HRM",
            'OUT_PROCESS_ID': '',
            'LOGIN_ID': '',
            'PASSWORD': ''
          },
          'PWDATA': {

          },
          'PWERROR': {}
        }
      }
    }
  }



  sample_MB_Authentication() {
    return {
      'MB_Authentication': {
        'Row': [
          {

            'user_mobile': ''


          }
        ]
      }
    }
  }


  sample_MB_Authentication_GMAIL() {
    return {
      'MB_Authentication_gmail': {
        'Row': [
          {

            'token': '',
            'email': '',
            'x_fb_token':'',

          }
        ]
      }
    }
  }

  sample_check_if_exists() {
    return {
      'check_if_exists': {
        'Row': [
          {

            'token': '',
            'email': '',


          }
        ]
      }
    }
  }

  sample_portal_logout() {
    return {
      'portal_logout': {
        'Row': [
          {

            'email': '',
             'reg_no':'',

          }
        ]
      }
    }
  }

  sample_candidate_registration() {
    return {
      'candidate_registration': {
        'Row': [
          {

            'x_token': '',
            'x_email_id': '',
            'x_candidate_name': '',
            'x_father_name': '',
            'x_date_of_birth': '',
            'x_mother_name': '',
            'x_religion': '',
            'x_category': '',
            'x_marital_status': '',
            'x_father_occupation': '',
            'x_mother_occupation': '',
            'x_father_org': '',
            'x_mother_org': '',
            'x_avg_monthly_come': '',
            'x_source_of_come': '',
            'x_village_town': '',
            'x_city': '',
            'x_post': '',
            'x_district': '',
            'x_state': '',
            'x_pin': '',
            'x_mobile_no1': '',
            'x_mobile_no2': '',
            'x_emergency_no': '',
            'x_emergency_relation': '',
            'x_aadhaar_no': '',
            'x_pan_no': '',
            'x_ten_pass_year': '',
            'x_ten_board': '',
            'x_ten_percent': '',
            'x_twelve_pass_year': '',
            'x_twelve_board': '',
            'x_twelve_percent': '',
            'x_iti_status': '',
            'x_iti_type': '',
            'x_iti_council': '',
            'x_iti_stitute': '',
            'x_trade': '',
            'x_iti_pass_year': '',
            'x_grad_oth_pass_year': '',
            'x_grad_oth_details': '',
            'x_grad_oth_percent': '',
            'x_empl_exchange_place': '',
            'x_apprenticeship_from': '',
            'x_apprenticeship_to': '',
            'x_apprenticeship_org': '',
            'x_prev_exp_total': '',
            'x_prev_exp_year': '',
            'x_prev_exp_months': '',
            'x_prev_company_name': '',
            'x_prev_salary': '',
            'x_reason_leavg': '',
            'x_contact_details': '',
            'x_contact_details2': '',
            'x_referral': '',
            'x_physical_disability': '',
            'x_physical_disability_Spec':'',
            'x_other_physical_disability':'',
            'x_special_abled_certificate_percentage':'',
            'x_color_blindness': '',
            'x_gender':'',
'x_sibling_details': {},
  'x_emergency_no2':'', 
  'x_emergency_relation2':'',
          }
        ]
      }
    }
  }


  sample_selection_criteria() {
    return {
      'selection_criteria': {
        'Row': [
          {

            'token': '',
            'email': '',


          }
        ]
      }
    }
  }
  sample_select_image() {
    return {
      'select_image': {
        'Row': [
          {

            'token': '',
            'email': '',


          }
        ]
      }
    }
  }
  sample_file_upload() {
    return {
      'file_upload': {
        'Row': [
          {

            'file': '',
            'file_name': '',
            'file_ext': '',
            'token': '',
            'email_id': '',
            'type': '',
            'blank': '',

          }
        ]
      }
    }
  }
  sample_get_questions() {
    return {
      'get_questions': {
        'Row': [
          {

            'token': '',
            'email_id': '',
            'reg_no': '',
            'x_ques_trade': '',

          }
        ]
      }
    }
  }

  sample_test_submit_evaluation
    () {

    return {
      'test_submit_evaluation': {
        'Row': [
          {

            'x_reg_no': '',
            'x_email_id': '',
            'x_candidate_name': '',
            'x_eval_data': '',
            'x_set_id': '',
            'x_ques_trade': '',
            'x_trade': '',
            'x_token': '',

          }
        ]
      }
    }
  }


  sample_check_final_selection() {
    return {

      'check_final_selection': {
        'Row': [
          {
            'x_token': '',
            'x_email_id': '',
            'x_reg_no': '',
          }
        ]
      }
    }
  }

  sample_browser_refresh() {
    return {
      'browser_refresh': {
        'Row': [
          {
            'x_token': '',
            'x_email_id': '',
            'x_endpoint': '',
          }
        ]
      }
    }
  }
  sample_update_fb_token() {
    return {
      'update_firebase_token': {
        'Row': [
          { 

            'x_email_id': '',
             'x_fb_token':'',

          }
        ]
      }
    }
  }

  sample_get_alldistricts() {
    return {

      'get_alldistricts': {
        'Row': [
          {
            'x_token': '',
            'x_email_id': '',
          }
        ]
      }
    }
  }

}