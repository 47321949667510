export const environment = {
  production: true,
  firebase : {
    apiKey: "AIzaSyB4X8v7HUfKVmEOWlDha-fvSjYN9DVAT_A",
    authDomain: "recruitmentapp-358b1.firebaseapp.com",
    projectId: "recruitmentapp-358b1",
    storageBucket: "recruitmentapp-358b1.appspot.com",
    messagingSenderId: "798982071507",
    appId: "1:798982071507:web:4944b8d02fe51555e32026",
    measurementId: "G-S9FEQ2CPK3"
  }
};
