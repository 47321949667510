import { Component } from '@angular/core';
import { ActionSheetController, AlertController, NavController, Platform } from '@ionic/angular';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { LoadingController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";
// import * as data from './dropdwns.json';
import { RequestModelService } from '../providers/request/request-model.service';
import { HmclService } from '../providers/service/hmcl.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  products: any = [];
  httpClient: any;
  constructor(private alertCtrl: AlertController,private afMessaging: AngularFireMessaging,
    private toast: ToastController, private loader: LoadingController,
    private request: RequestModelService, private hmclService: HmclService, private router: Router,)
    
    {
   
      this.requestPushNotificationsPermission();

    }

  
    requestPushNotificationsPermission() { // requesting permission
      this.afMessaging.requestToken // getting tokens
        .subscribe(
          (token) => { // USER-REQUESTED-TOKEN
            localStorage.setItem("firetoken", token);

          // this.update_fb_token(token);
            // console.log('Permission granted! Save to the server!', token);
          },
          (error) => {
            console.error(error);
          }
        );
    }
  

    update_fb_token(token) {
      var email = localStorage.getItem("email");

      // debugger
      // console.log("update_fb_token email" + email, "token" + token);
  
      this.loader.create({
        message: "Loading..."
      }).then((loadingElement) => {
        loadingElement.dismiss();
  
        let REQ_DATA = this.request.getStaticRequestData();
        REQ_DATA.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID = this.hmclService.ApiMapping.update_firebase_token;
        let PWDATA = this.request.sample_update_fb_token();
        PWDATA.update_firebase_token.Row[0].x_fb_token = token;
        PWDATA.update_firebase_token.Row[0].x_email_id = email;
  
        REQ_DATA.PWSESSIONRS.PWPROCESSRS.PWDATA = PWDATA;
  
        this.hmclService.CallApi(REQ_DATA, token, email).subscribe((data: any) => {
  
          this.loader.dismiss();
  
          var update_fb_token = data.PWSESSIONRS[0].PWPROCESSRS.PWDATA.update_firebase_token.Row[0];
  
          
  
          if (update_fb_token.isSuccess == '1') {
            if (update_fb_token.registered_user == '1') {
           
              // console.log("update_fb_token_success hsjksh" + update_fb_token);

  
            }
          }
         
        });
      }, (err) => {
        if (err.status === 401) {
  
        }
      })
  
  
    }

    ngOnInit(){
     // debugger;
     
    }
  
}
