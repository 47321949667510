import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
// import * as CryptoJS from 'crypto-js';

declare var window;

// @Injectable()
@Injectable({
  providedIn: 'root'
})
export class HmclService {

 url = "https://mobapp.heromotocorp.com/api/cloudware_prod?ORG_ID=HRM"
 //url="https://mobapp.heromotocorp.com/api/cloudware_prod?ORG_ID=HRMPT"
  productionUrl: boolean = true;
  retailerCode: string = "";
  token: string = "";
  constructor(private http: HttpClient,
    private http2: HTTP) { }



  ApiMapping = {
    'MB_Authentication_gmail': 'MB_Authentication_gmail',
    'check_if_exists': 'check_if_exists',
    'candidate_registration': 'candidate_registration',
    'selection_criteria': 'selection_criteria',
    'get_test_link': 'get_test_link',
    'get_questions': 'get_questions',
    'select_image': 'select_image',
    'file_upload': 'file_upload',
    'test_submit_evaluation': 'test_submit_evaluation',
    'portal_logout': 'portal_logout',
    'check_final_selection': 'check_final_selection',
    'browser_refresh': 'browser_refresh',
    'update_firebase_token':'update_firebase_token',
    'get_alldistricts':'get_alldistricts',
  }

  ReverseApiMapping = {
    'MB_Authentication_gmail': 'MB_Authentication_gmail',
    'check_if_exists': 'check_if_exists',
    'candidate_registration': 'candidate_registration',
    'selection_criteria': 'selection_criteria',
    'get_test_link': 'get_test_link',
    'get_questions': 'get_questions',
    'select_image': 'select_image',
    'file_upload': 'file_upload',
    'test_submit_evaluation': 'test_submit_evaluation',
    'portal_logout': 'portal_logout',
    'check_final_selection': 'check_final_selection',
    'browser_refresh': 'browser_refresh',
    'update_firebase_token':'update_firebase_token',
    'get_alldistricts':'get_alldistricts',

  };

  ApiMapping_callType = {
    'MB_Authentication_gmail': '17',
    'check_if_exists': '1',
    'candidate_registration': '1',
    'selection_criteria': '1',
    'get_test_link': '1',
    'get_questions': '1',
    'test_submit_evaluation': '1',
    'select_image': '1',
    'file_upload': '6',
    'portal_logout': '1',
    'check_final_selection': '1',
    'browser_refresh': '1',
    'update_firebase_token':'1',
    'get_alldistricts':'1',

  };



  CallApi(body, token, auth_type_value) {
    //console.log(body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID);

    var process_id = body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID;

    var headers = { "Content-Type": "application/json", 'Authorization': "" };
    if (
      process_id == this.ApiMapping.MB_Authentication_gmail) {

      body.PWSESSIONRS.PWPROCESSRS.PWHEADER.LOGIN_ID = auth_type_value;
      return this.CallApiFinal(body, headers);

    }
    else {
      var tokentemp = token + "::" + auth_type_value + "::HRM";


      tokentemp = "Basic " + btoa(tokentemp);
      ////console.log(tokentemp);



      var headers = { "Content-Type": "application/json", 'Authorization': tokentemp };
      body.PWSESSIONRS.PWPROCESSRS.PWHEADER.LOGIN_ID = auth_type_value;
      return this.CallApiFinal2(body, headers);
    }
    // var url = "http://10.200.2.231/PlatwareAnalyzer_03_01_65_uat/GatewayAnalyserJson?ORG_ID=HMM";

  }


  CallApiFinal2(body, headers) {
    //  var url = "https://mobileapps01.heromotocorp.com/cloudware_uat/GatewayAnalyserJson?ORG_ID=HSLP";

    body.PWSESSIONRS.PWPROCESSRS.PWHEADER.IN_PROCESS_ID = this.ApiMapping_callType[body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID] || "";
    //to manage request sequence
    let row_length = body.PWSESSIONRS.PWPROCESSRS.PWDATA[body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID].Row.length;
    let keys = row_length > 0 ? Object.keys(body.PWSESSIONRS.PWPROCESSRS.PWDATA[body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID].Row[0]) : [];
    let orderedData = {};
    let unorderedData = row_length > 0 ? body.PWSESSIONRS.PWPROCESSRS.PWDATA[body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID].Row[0] : {};
    // addding numbers before key to maintain sequesnce on server 
    for (var i = 0; i < keys.length; i++) {
      orderedData[i] = unorderedData[keys[i]];
    }
    body.PWSESSIONRS.PWPROCESSRS.PWDATA = {};
    body.PWSESSIONRS.PWPROCESSRS.PWDATA[this.ApiMapping[body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID]] = {}
    body.PWSESSIONRS.PWPROCESSRS.PWDATA[this.ApiMapping[body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID]].Row = [];
    body.PWSESSIONRS.PWPROCESSRS.PWDATA[this.ApiMapping[body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID]].Row.push(orderedData);

    body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID = this.ApiMapping[body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID];

    // this.http2.setDataSerializer("json");
    // return this.http2.post(this.url, body, headers);
    return this.http.post(this.url, body, { headers: headers });
  }




  CallApiFinal(body, headers) {
    // var url = "https://mobileapps01.heromotocorp.com/cloudware_uat/GatewayAnalyserJson?ORG_ID=HSLP";

    // let token = "";
    // if (this.sessionVariable.getEmpCred() != null) {
    //   this.email = this.sessionVariable.getEmpCred().emp_email_id;
    //   this.token = this.sessionVariable.getToken();
    //   token = "Basic " + btoa(this.token + "::" + this.email + "::123");
    // }
    // else if (this.sessionVariable.getUserCred() != null) {
    //   this.dealer = this.sessionVariable.getSelectedDealerData(this.sessionVariable.getSelectedDealerIndex()).dealer_code;
    //   this.token = this.sessionVariable.getToken();
    //   token = "Basic " + btoa(this.token + "::" + this.dealer + "::123");
    // }

    // //console.log("token" + token);


    // var options = { 'Content-Type': 'application/json', 'Authorization': token };

    // var headers = { "Content-Type": "application/json", 'Authorization': token };

    // this.HEADER = { 'Content-Type': 'application/json', 'Authorization': token };
    // let options: RequestOptionsArgs = {
    //   headers: this.HEADER || ""
    // }


    // var url = this.isLive ? this.produrl : this.uaturl;//"https://mobileapps01.heromotocorp.com/cloudware_uat/GatewayAnalyserJson?ORG_ID=HEP";
    // var url = "https://mobileapps01.heromotocorp.com/cloudware_prod/GatewayAnalyserJson?ORG_ID=HEP";
    body.PWSESSIONRS.PWPROCESSRS.PWHEADER.IN_PROCESS_ID = this.ApiMapping_callType[body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID] || "";
    // body.PWSESSIONRS.PWPROCESSRS.PWHEADER.LOGIN_ID = this.email || "";

    //to manage request sequence
    let row_length = body.PWSESSIONRS.PWPROCESSRS.PWDATA[body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID].Row.length;
    let keys = row_length > 0 ? Object.keys(body.PWSESSIONRS.PWPROCESSRS.PWDATA[body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID].Row[0]) : [];
    let orderedData = {};
    let unorderedData = row_length > 0 ? body.PWSESSIONRS.PWPROCESSRS.PWDATA[body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID].Row[0] : {};
    // addding numbers before key to maintain sequesnce on server 

    body.PWSESSIONRS.PWPROCESSRS.PWDATA = {};

    for (var i = 0; i < keys.length; i++) {
      orderedData[i] = unorderedData[keys[i]];
    }

    body.PWSESSIONRS.PWPROCESSRS.PWDATA[this.ApiMapping[body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID]] = {}
    body.PWSESSIONRS.PWPROCESSRS.PWDATA[this.ApiMapping[body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID]].Row = [];
    body.PWSESSIONRS.PWPROCESSRS.PWDATA[this.ApiMapping[body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID]].Row.push(orderedData);


    body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID = this.ApiMapping[body.PWSESSIONRS.PWPROCESSRS.PWHEADER.OUT_PROCESS_ID];
    //console.log(body);

    // this.http2.setDataSerializer("json");
    // return this.http2.post(this.url, body, headers);

    return this.http.post(this.url, body, { headers: headers });
  }


  CallApi2(body) {
    var url = "";
    var headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post(url, body, { headers: headers });

  }






  // encrypteTheRequest(data)
  // {

  //   var key = CryptoJS.enc.Utf8.parse('dxgbfh35436dgfghgjdgfhwqwqwqwqwq');
  //   var iv = CryptoJS.enc.Utf8.parse('gdqy4kl2scvg9tqw');

  //   var text = data;

  //   var encrypted = CryptoJS.AES.encrypt(text, key, {
  //     keySize: 256 / 8,
  //     mode: CryptoJS.mode.CBC,
  //     iv:iv,
  //     padding: CryptoJS.pad.Pkcs7
  //     });

  //   return encrypted;
  // }


  // decrypteTheRequest(encrypteddata)
  // {

  //   var key = CryptoJS.enc.Utf8.parse('dxgbfh35436dgfghgjdgfhwqwqwqwqwq');
  //   var iv = CryptoJS.enc.Utf8.parse('gdqy4kl2scvg9tqw');


  //     var decrypted = CryptoJS.AES.decrypt(encrypteddata, key, {
  //     keySize: 256 / 8,
  //     mode: CryptoJS.mode.CBC,
  //     iv:iv,
  //     padding: CryptoJS.pad.Pkcs7
  //     });

  //     return decrypted.toString(CryptoJS.enc.Utf8);
  // }



}
