import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'status',
    loadChildren: () => import('./status/status.module').then( m => m.StatusPageModule)
  },
  
  // {
  //   path: 'splash',
  //   loadChildren: () => import('./splash/splash.module').then( m => m.SplashPageModule)
  // },
  {
    path: 'personal-details',
    loadChildren: () => import('./personal-details/personal-details.module').then( m => m.PersonalDetailsPageModule)
  },
  {
    path: 'professional-details',
    loadChildren: () => import('./professional-details/professional-details.module').then( m => m.ProfessionalDetailsPageModule)
  },
  {
    path: 'educational-details',
    loadChildren: () => import('./educational-details/educational-details.module').then( m => m.EducationalDetailsPageModule)
  },
  {
    path: 'address-details',
    loadChildren: () => import('./address-details/address-details.module').then( m => m.AddressDetailsPageModule)
  },
  {
    path: 'registered-user',
    loadChildren: () => import('./registered-user/registered-user.module').then( m => m.RegisteredUserPageModule)
  },
  {
    path: 'interviewquestions',
    loadChildren: () => import('./interviewquestions/interviewquestions.module').then( m => m.InterviewquestionsPageModule)
  },
  {
    path: 'document-upload',
    loadChildren: () => import('./document-upload/document-upload.module').then( m => m.DocumentUploadPageModule)
  },
  {
    path: 'interview-process-completed',
    loadChildren: () => import('./interview-process-completed/interview-process-completed.module').then( m => m.InterviewProcessCompletedPageModule)
  },
  {
    path: 'personaltest',
    loadChildren: () => import('./personaltest/personaltest.module').then( m => m.PersonaltestPageModule)
  },
  {
    path: 'languageselect',
    loadChildren: () => import('./languageselect/languageselect.module').then( m => m.LanguageselectPageModule)
  },  {
    path: 'note-info',
    loadChildren: () => import('./note-info/note-info.module').then( m => m.NoteInfoPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
