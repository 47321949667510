// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
  // firebase : {
  //   apiKey: "AIzaSyB4X8v7HUfKVmEOWlDha-fvSjYN9DVAT_A",
  //   authDomain: "recruitmentapp-358b1.firebaseapp.com",
  //   projectId: "recruitmentapp-358b1",
  //   storageBucket: "recruitmentapp-358b1.appspot.com",
  //   messagingSenderId: "798982071507",
  //   appId: "1:798982071507:web:4944b8d02fe51555e32026",
  //   measurementId: "G-S9FEQ2CPK3"
  // }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
