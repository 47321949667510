import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClient, HttpClientModule } from '@angular/common/http'; 
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP } from '@ionic-native/http/ngx';
import { FormatTimePipe } from './interviewquestions/interviewquestions.page';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../../src/environments/environment.prod';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';




@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  
  imports: [BrowserModule,HttpClientModule, IonicModule.forRoot(), AppRoutingModule,   
     AngularFireDatabaseModule, 
     AngularFireModule.initializeApp(environment.firebase),
      ServiceWorkerModule.register('combined-sw.js', {enabled: environment.production}),
    AngularFireMessagingModule,
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true
    })
    ],
  providers: [HTTP,HttpClient,DatePipe,
    { provide: LocationStrategy, useClass: HashLocationStrategy }],
  
  bootstrap: [AppComponent],
})
export class AppModule {

}
//HttpClient,HttpClientModule,//AngularFireModule.initializeApp(environment.firebase),
//{ provide: LocationStrategy, useClass: HashLocationStrategy },